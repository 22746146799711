.OuterBox{
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
}
.Error{
    background:red;
    padding:2%;
    margin:1%;
    text-align: center;
    color: white;
}
.MainCol{
    background-color: steelblue;
}
.Box{
    width:40%;
    padding: 0;
    background-color: rgb( 36,50,59);
}
.Img{
    display:block;
    width: 100%;
    height: 500px;
}
.Col:hover{
    background-color: pink;
}
.Col{
    text-align: center;
    margin: 0;
    padding: 3%;
    cursor: pointer;
    font-weight: bold;
}
.Login{
    background-color: rgb( 36,50,59);
    padding: 5% 15%;
}
@media (max-width:992px){
    .Box{
        width: 50%;
    }
}
@media (max-width:768px){
    .Box{
        width: 70%;
    }
}
@media (max-width:576px){
    .Box{
        width: 95%;
    }
}