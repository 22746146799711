.SearchInput {
  position: relative;
  width: 300px;
}
.SearchButton {
  margin-left: 10px;
}
.TooltipIcon {
  margin: 4px;
  cursor: pointer;
}
.Response {
  padding-left: 0;
}
.Response2 {
  padding-right: 0;
}
.Row {
  margin: 0;
}
.CenterRow {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100%;
}
.P {
  margin: 0;
}
.Li {
  list-style: none;
  padding: 4% 0;
  color: white;
  margin: 0 0 3% 0;
  cursor: pointer;
}
.Col {
  padding: 0;
}
.Ul {
  padding: 0;
  background-color: #383838;
  height: 100%;
  margin: 0;
}
.DropZone {
  margin: 4%;
}
.Box {
  border: 1px solid steelblue;
  height: 100%;
  padding: 2%;
}
.Box22 {
  border: 1px solid steelblue;
  height: 100%;
  padding: 2%;
}
.Box2 {
  border: 1px solid steelblue;
  height: 98%;
  padding: 2%;
  margin-right: 7%;
}
.DropZoneBox {
  border: 1px solid steelblue;
  height: 93%;
  padding: 2%;
  margin-right: 7%;
}
.Center {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 992px) {
  .Li {
    padding: 2%;
    margin: 1%;
  }
  .Response {
    padding-left: 15px;
  }
  .Response2 {
    padding-right: 15px;
  }
  .SearchInput {
    width: 270px;
  }
  .SearchButton {
    padding: 0.3rem;
  }
}
@media (max-width: 576px) {
  .SearchInput {
    position: relative;
    width: 150px;
    font-size: 8px;
  }
  .SearchButton {
    margin-left: 5px;
    font-size: 10px;
    font-weight: bold;
    padding: 0.2rem;
  }
}
@media (max-width: 450px) {
  .SearchInput {
    position: relative;
    width: 120px;
    font-size: 8px;
  }
  .SearchButton {
    margin-left: 3px;
    font-size: 8px;
    font-weight: bold;
  }
}
