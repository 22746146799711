.Heading{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    padding: 0.7%;
}
.Head{
    height: 100vh;
}
.Logout{
    padding: 1%; 
    font-weight: bold;
    background-color: #ff2424;
    color: white;
}
.Logout:hover{
    background-color: #f75c5c;
    cursor: pointer;
}
.Company{
    font-weight: bold;
    font-size: x-large;
    color: white;
    padding: 6%;
}
.Li{
    list-style: none;
    padding: 3% 3%;
    color: white;
    margin:0.7%;
}
.Ul{
    padding: 0;
    background-color: #383838;
    height:100vh;
    overflow-y: auto;
}
.Col{
    padding: 3px;
}
.Li:hover{
    background-color: steelblue;
    cursor: pointer;
}
.Box{
    background-color: steelblue;
    color: white;
    height: 98%;
    padding: 2%;
    height: 140px;
    box-shadow: 1px 1px 2px 2px #0d0d0d6b;
} 
.MainBox{
    display: flex;
    justify-content: space-around;
}
.P{
    margin:0;
}
@media (max-width:1200px){
    .Box{
        width: 100%;
    }
}
@media (max-width:992px){
    .Company{
        font-size: 1.2rem;
        padding: 10%;
    }
}
@media (max-width:768px){
    .Box{
        width: 100%;
    }
    .Company{
        font-size: large;
        padding: 10%;
    }
}
@media (max-width:576px){
    .Box{
        height: 130px;
    }
    .Heading{
        font-size: x-large;
        padding: 5%;
    }
}
 
 